<div class="page page--secondary" [class.page--blured]="menu"  (scroll)="pageScroll($event, redeemBtn)">
    <div class="page__inner">
        <a [routerLink]="getBackLink()" class="page__back">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">EXISTING USER</h1>
        <h2 class="page__provider">{{getGameProviderName()}}</h2>
        <div class="page__head">
            <div class="page__left">
                <div class="page__tel" #userTel>{{getPhone(userInfo) | mask: getPhoneMask(userInfo)}}</div>
                <div class="page__pin">{{getPin(userInfo)}}</div>
            </div>
            <div class="page__right">
<!--                <button (click)="menu = true" class="page__options">-->
<!--                    <svg width="21" height="5" viewBox="0 0 21 5" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>-->
<!--                    </svg>-->
<!--                </button>-->
            </div>
        </div>
        <div class="page__form" [hidden]="isTemplate2()">
            <form [formGroup]="sellForm"
                  (ngSubmit)="onSubmit()"
                  class="form-simple form-simple--primary">
                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Sweeps Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>
                <!--<div class="form-simple__item form-simple__item&#45;&#45;amount hide-riverpay" [class.is-visible]="!isRiverPay || false">-->
                <div class="form-simple__item form-simple__item--amount hide-riverpay" [class.is-visible]="false">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="usdc_amount"
                           placeholder="Crypto Amount (USDC)"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>

                <div class="form-simple__btns">
                    <button class="button snake-btn is-notouched" [disabled]="!sellForm.controls.amount.value && !sellForm.controls.usdc_amount.value">
                        SELL
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>

        <div class="page__form">
            <div class="balance">
                <h2 class="balance__title">AVAILABLE WINNING BALANCE</h2>
                <div class="balance__amount">{{getWinningBalance() | currency : 'USD' : 'symbol'}}</div>
            </div>

            <form [formGroup]="buyForm" (ngSubmit)="onSubmitBuy()" class="form-simple form-simple--primary">
                <div class="form-simple__item form-simple__item--amount">
                    <input mask="separator.0"
                           thousandSeparator=","
                           formControlName="amount"
                           placeholder="Amount"
                           [type]="'tel'"
                           class="form-simple__input"
                           autocomplete="off">
                    <span>.00</span>
                </div>

                <div class="form-simple__btns">
                    <button #redeemBtn class="button snake-btn is-notouched" [disabled]="buyForm.invalid">
                        Redeem
                        <svg><rect></rect></svg>
                    </button>
                </div>
            </form>
        </div>

        <div class="page__body">

            <div class="dropdown js-dropdown js-debit-parent is-hidden">
                <div class="dropdown__head" (click)="toggleDropdown($event)">
                    <div class="dropdown__title">
                        ISSUED FUNDS
                    </div>
                    <div class="dropdown__total">
                        {{getIssuedFunds(userInfo) | currency}}
                    </div>
                    <svg class="dropdown__arrow" width="21" height="12">
                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                    </svg>
                </div>
                <div class="dropdown__body" (scroll)="scroll($event, debitData)">
                    <table class="transactions">
                        <thead>
                            <tr class="transactions__head">
                                <th class="transactions__type">TYPE</th>
                                <th class="transactions__date">DATE</th>
                                <th class="transactions__free">FREE</th>
                                <th class="transactions__amount">AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let action of debitData.items" class="transactions__item js-debit-item">
                                <td class="transactions__type">
                                    <svg width="18px" height="14px">
                                        <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPayment(action.payment_method)"></use>
                                    </svg>
                                </td>
                                <td class="transactions__date">{{getDate(action.created) | date: 'MM-dd-yyyy'}}</td>
                                <td class="transactions__free">{{getAmount(action, AMOUNT_TYPE.free) | currency}}</td>
                                <td class="transactions__amount">{{getAmount(action, AMOUNT_TYPE.amount) | currency}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="dropdown js-dropdown js-credit-parent is-hidden">
                <div class="dropdown__head" (click)="toggleDropdown($event)">
                    <div class="dropdown__title">
                        REDEMPTIONS
                    </div>
                    <div class="dropdown__total">
                        {{getRedemtions(userInfo) | currency}}
                    </div>
                    <svg class="dropdown__arrow" width="21" height="12">
                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                    </svg>
                </div>
                <div class="dropdown__body" (scroll)="scroll($event, creditData)">
                    <table class="transactions">
                        <tr class="transactions__head">
                            <th class="transactions__type">TYPE</th>
                            <th class="transactions__date">DATE</th>
                            <th class="transactions__amount">AMOUNT</th>
                        </tr>
                        <tr *ngFor="let action of creditData.items" class="transactions__item js-credit-item">
                            <td class="transactions__type">
                                <svg *ngIf="getPayout(action.type) !== 'bill'" width="18px" height="14px">
                                    <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPayout(action.type)"></use>
                                </svg>

                                <svg *ngIf="getPayout(action.type) === 'bill'" width="18px" height="16px" style="margin-left: -2px">
                                    <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getPayout(action.type)"></use>
                                </svg>
                            </td>
                            <td class="transactions__date">{{getDate(action.created) | date: 'MM-dd-yyyy'}}</td>
                            <td class="transactions__amount">{{action.amount | currency}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <div class="dropdown js-dropdown js-crypto-parent is-hidden hide-riverpay" [class.is-visible]="!isRiverPay">
                <div class="dropdown__head" (click)="toggleDropdown($event)">
                    <div class="dropdown__title">
                        Sold Crypto
                    </div>
                    <div class="dropdown__total">
                        {{getCryptoTotal(userInfo) | currency}}
                    </div>
                    <svg class="dropdown__arrow" width="21" height="12">
                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                    </svg>
                </div>
                <div class="dropdown__body" (scroll)="scroll($event, cryptoData)">
                    <table class="transactions">
                        <tr class="transactions__head">
                            <th class="transactions__type">TYPE</th>
                            <th class="transactions__date">DATE</th>
                            <th class="transactions__amount">AMOUNT</th>
                        </tr>
                        <tr *ngFor="let action of cryptoData.items" class="transactions__item js-crypto-item">
                            <td class="transactions__type">
                                <svg width="28px" height="20px" style="margin-left: -4px">
                                    <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#'+getCrypto(action.currency_short_name)"></use>
                                </svg>
                            </td>
                            <td class="transactions__date">{{getDate(action.created) | date: 'MM-dd-yyyy'}}</td>
                            <td class="transactions__amount">{{action.size | currency}}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
<!--<div class="addition-menu" [class.is-active]="menu" *ngIf="userInfo">-->
<!--    <ul class="addition-menu__list">-->
<!--&lt;!&ndash;        <li class="addition-menu__item">&ndash;&gt;-->
<!--&lt;!&ndash;            <button (click)="askFreezeUser()">Freeze</button>&ndash;&gt;-->
<!--&lt;!&ndash;        </li>&ndash;&gt;-->
<!--        <li class="addition-menu__item">-->
<!--            <button (click)="askDeleteUser()">Delete</button>-->
<!--        </li>-->
<!--    </ul>-->
<!--    <button (click)="menu = false" class="addition-menu__btn">Cancel</button>-->
<!--</div>-->
