import {Component, OnInit, HostListener, ElementRef, ViewChild,} from '@angular/core';
import {routerTransition, slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ExistingUserService} from "../../../service/sell/existingUser.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserInfo} from "../../../models/userInfo";
import {PAYMENT_TYPES} from "../../../config/payment_types";
import {PAYOUT_TYPES} from "../../../config/payout_types";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {formatNumber,formatCurrency} from "@angular/common";
import {LoaderService} from "../../../service/loader.service";
import {PhoneMaskService} from "../../../service/phoneMask.service";
import {ResetPageScrollService} from "../../../service/pageScroll/resetPageScroll";
import {CRYPTO_TYPES} from "../../../config/crypto_types";
import {PaginationDataModel, SetPaginationDataModel} from "../../../models/scrollPagination/paginationData.model";
import {PaginationScrollService} from "../../../service/pageScroll/paginationScroll.service";
import {PaginationData} from "../../../models/scrollPagination/paginationData";
import {GamesProviderService} from "../../../service/game/gamesProvider.service";
import {TemplateService} from "../../../service/template.service";
import {AMOUNT_TYPE, PAYMENT_METHOD_TYPE} from "./config";
import {PartnerService} from "../../../service/partner.service";

@Component({
    selector: 'app-existing-user',
    templateUrl: './existing-user-info.component.html',
    styleUrls: ['./existing-user-info.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': '',
          '[class.is-menu]': 'menu',}
})
export class ExistingUserInfoComponent implements OnInit {
    sellForm: FormGroup;
    buyForm: FormGroup;
    userInfo;
    PAYMENT_TYPES = PAYMENT_TYPES;
    PAYOUT_TYPES = PAYOUT_TYPES;
    CRYPTO_TYPES = CRYPTO_TYPES;
    menu = false;
    userId;
    phoneMask = this.phoneMaskService.setPhoneMask();

    prevPageTopOffset = 0;
    prevChildTopOffset = 0;
    maxPageScrollPosition = 0;

    gameProviderId;
    singleGame;

    AMOUNT_TYPE = AMOUNT_TYPE;
    PAYMENT_METHOD_TYPE = PAYMENT_METHOD_TYPE;

    isRiverPay = this.partnerService.isRiverPay();

    debitData: PaginationDataModel = new PaginationData('debit', '.js-debit-item', '.js-debit-parent');
    creditData: PaginationDataModel = new PaginationData('credit', '.js-credit-item', '.js-credit-parent');
    cryptoData: PaginationDataModel = new PaginationData('crypto', '.js-crypto-item', '.js-crypto-parent');

    @ViewChild('userTel', {static: false}) userTel: ElementRef;

    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private phoneMaskService: PhoneMaskService,
                private resetPageScrollService: ResetPageScrollService,
                private router: Router,
                private partnerService: PartnerService,
                private paginationScrollService: PaginationScrollService,
                private gameProviderService: GamesProviderService,
                private templateService: TemplateService,
                private existingUserService: ExistingUserService) {

    }


    ngOnInit() {
        this.sellForm = this.formBuilder.group({
            amount: [null],
            usdc_amount: [null],
        });

        this.buyForm = this.formBuilder.group({
            amount: [null, Validators.required],
        });

        this.route.params.subscribe((params: Params) => {
            this.userId = params.id;
            this.gameProviderId = this.route.snapshot.queryParams.gameId;

            this.getUserInfo();
        });
        this.route.queryParams.subscribe(params => {
            this.singleGame = params.single;
        });

        document.body.addEventListener('touchstart', (e) => {
            this.resetPageScrollService.onTouch(e);
        });
    }

    onSubmit() {
        const amount = this.sellForm.controls.amount.value;
        const phone = this.userTel.nativeElement.innerHTML;
        const usdc_amount = this.sellForm.controls.usdc_amount.value ? this.sellForm.controls.usdc_amount.value : 0;

        let text = `${phone} <br><br>Membership/Sweeps: ${formatCurrency(amount, 'en','$')}${`<br><br>USDC: ${formatCurrency(usdc_amount, 'en','$')}`} `;
        if(this.isRiverPay){
            text = `${phone} <br><br>Please confirm ${formatCurrency(amount, 'en','$')} contribution toward Cross 10K Gold Pen. `;
        }

        this.popupComponent.showPopupAgreement({text: text}, (status) => {
            if (status) {
                this.submit();
            }
        });
    }

    onSubmitBuy() {
        const amount = this.buyForm.controls.amount.value;
        const phone = this.userTel.nativeElement.innerHTML;

        this.popupComponent.showPopupAgreement({text: `${phone} <br><br> ${formatCurrency(amount, 'en','$')}`}, (status) => {
            if (status) {
                this.submitBuy();
            }
        });
    }

    submit() {
        const userId = this.route.snapshot.params.id;
        const amount = this.sellForm.controls.amount.value ? this.sellForm.controls.amount.value : 0;
        const usdc_amount = this.sellForm.controls.usdc_amount.value ? this.sellForm.controls.usdc_amount.value : 0;
        const gameProviderId = this.gameProviderId;

        this.loaderService.show();
        this.existingUserService.sell(userId, amount, usdc_amount, gameProviderId).subscribe(data => {
            this.loaderService.hide();
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.sellForm.reset();

                this.getUserInfo();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    submitBuy() {
        const userId = this.route.snapshot.params.id;
        const amount = this.buyForm.controls.amount.value;
        const gameProviderId = this.gameProviderId;

        this.loaderService.show();
        this.existingUserService.buy(userId, amount, gameProviderId).subscribe(data => {
            this.loaderService.hide();
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
                this.buyForm.reset();

                this.getUserInfo();
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    getUserInfo(){
        const gameProviderId = this.gameProviderId;

        this.loaderService.show();
        this.existingUserService.getUserInfo(this.userId, gameProviderId).subscribe(data => {

            try {
                if (data.status) {
                    this.userInfo = data;

                    const debitDataSettings: SetPaginationDataModel = {
                        itemsList: data.debit,
                        scrollLimit: data.debitLimit,
                        total: data.debitCount
                    }

                    this.initScrolledData(this.debitData, debitDataSettings);


                    const creditDataSettings: SetPaginationDataModel = {
                        itemsList: data.credit,
                        scrollLimit: data.creditLimit,
                        total: data.creditCount
                    }

                    this.initScrolledData(this.creditData, creditDataSettings);


                    if (data.crypto && data.crypto.length) {
                        const cryptoDataSettings: SetPaginationDataModel = {
                            itemsList: data.crypto,
                            scrollLimit: data.cryptoLimit,
                            total: data.cryptoCount
                        }

                        this.initScrolledData(this.cryptoData, cryptoDataSettings);
                    }
                }
            } catch (e) {
                this.popupComponent.showPopupError({text: 'Sorry there was an error try again later.'});
            }

        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });

    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }

    getPhone(item){
        const activityItem = new UserInfo(item);
        return activityItem.phone;
    }

    getPin(item){
        const activityItem = new UserInfo(item);
        let pin = activityItem.pin;
        if(pin.length > 5){
            return pin;
        }

        return '';
    }

    getId(item){
        const activityItem = new UserInfo(item);
        return activityItem.id;
    }

    getIssuedFunds(item){
        const activityItem = new UserInfo(item);
        return activityItem.totalDebit;
    }

    getRedemtions(item){
        const activityItem = new UserInfo(item);
        return activityItem.totalCredit;
    }

    getCryptoTotal(item){
        const activityItem = new UserInfo(item);
        return activityItem.totalCrypto;
    }

    getIssuedFundsItems(item){
        const activityItem = new UserInfo(item);
        return activityItem.debit;
    }

    getRedemtionsItems(item){
        const activityItem = new UserInfo(item);
        return activityItem.credit;
    }
    getPayout(id){
        const type = Object.keys(this.PAYOUT_TYPES).find(key => +this.PAYOUT_TYPES[key] === +id);
        switch(type) {
            case 'cash':
                return 'shop';

            case 'card':
                return 'card';

            case 'payPal':
                return 'payPal';

            case 'check':
                return 'bill';

            default:
                return 'shop';
        }
    }

    getPayment(id){
        const type = Object.keys(this.PAYMENT_TYPES).find(key => +this.PAYMENT_TYPES[key] === +id);
        switch(type) {
            case 'cash':
                return 'shop';

            case 'card':
                return 'card';

            case 'payPal':
                return 'payPal';

            default:
                return 'shop';
        }
    }

    getCrypto(id){
        const type = Object.keys(this.CRYPTO_TYPES).find(key => +this.CRYPTO_TYPES[key] === +id);
        switch(type) {
            case 'usdc':
                return 'usd_coin';
            default:
                return 'usd_coin';
        }
    }

    toggleDropdown(e) {
        const hidden = e.target.offsetParent.classList.contains('is-hidden') ? true : false;
        document.body.querySelectorAll('.js-dropdown').forEach(el => {
            el.classList.add('is-hidden');
        });

        if (hidden) {
            e.target.offsetParent.classList.remove('is-hidden');

            setTimeout(() => {
                const pageScroll = (<HTMLElement>document.querySelectorAll('.page')[0]).scrollHeight - (<HTMLElement>document.querySelectorAll('.page')[0]).offsetHeight;
                const scrollTo = pageScroll <= this.maxPageScrollPosition || this.maxPageScrollPosition === 0 ? pageScroll : this.maxPageScrollPosition;

                (<HTMLElement>document.querySelectorAll('.page')[0]).scrollIntoView({behavior: 'smooth'});
                (<HTMLElement>document.querySelectorAll('.page')[0]).scroll({
                    top: scrollTo,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 350)

        }
    }

    askFreezeUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to freeze the user?`}, (status) => {
            if (status) {
                this.freezeUser();
            }
        });
    }

    freezeUser() {
        this.existingUserService.freeze(this.userId).subscribe(data => {
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    askDeleteUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to delete the user?`,
            confirmButtonText: 'CONFIRM<svg><rect></rect></svg>',
            cancelButtonText: '<svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
                '<path d="M25.5 24.1719L18.3281 17L25.5 9.82812C25.8668 9.46136 25.8668 8.86676 25.5 8.5C25.1332 8.13324 24.5386 8.13324 24.1719 8.5L17 15.6719L9.82813 8.5C9.46136 8.13324 8.86676 8.13324 8.5 8.5C8.13324 8.86676 8.13324 9.46136 8.5 9.82812L15.6719 17L8.5 24.1719C8.13324 24.5386 8.13324 25.1332 8.5 25.5C8.86676 25.8668 9.46136 25.8668 9.82813 25.5L17 18.3281L24.1719 25.5C24.5386 25.8668 25.1332 25.8668 25.5 25.5C25.8668 25.1332 25.8668 24.5386 25.5 24.1719Z" fill="#FDFD88"/>\n' +
                '</svg>'}, (status) => {
            if (status) {
                this.deleteUser();
            }
        });
    }

    deleteUser() {
        this.existingUserService.delete(this.userId).subscribe(data => {
            if(data.status){
                this.popupComponent.showPopupSuccess({text:data.message});

                this.router.navigate(['/home/sell/existing-user']);
            } else {
                this.popupComponent.showPopupError({text:data.message});
            }
        });
    }

    getDate(date) {
        return date.replace(/\s/g, 'T');
    }


    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    // DETECTING SCROLL
    scroll(e, settings) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        // console.log(this.creditData, this.debitData, this.cryptoData);

        const direction = this.prevChildTopOffset > e.target.scrollTop ? 'up' : 'down';

        // console.log(direction === 'up', e.target.scrollTop === 0, document.querySelectorAll('.page')[0].style.overflowY === 'hidden')

        if (direction === 'up' && e.target.scrollTop === 0 && (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY === 'hidden') {
            (<HTMLElement>document.querySelectorAll('.page')[0]).style.overflowY = 'auto'
        }

        if (!settings.loading && settings.canScroll) {
            const elems = document.querySelectorAll(settings.selector);

            const rect = elems[elems.length - 1] !== undefined ? elems[elems.length - 1].getBoundingClientRect() : undefined;
            let rectStart;
            let documentRect = document.querySelector(settings.selectorParent).getBoundingClientRect();

            if (settings.prevPagination > 0) {
                rectStart = elems[0] !== undefined ? elems[Math.round(settings.limit*.8)].getBoundingClientRect() : undefined;
            } else {
                rectStart = undefined;
            }

            var st = e.target.pageYOffset || e.target.scrollTop;
            if (st > settings.lastScrollTop){
                if (rect !== undefined && rect.bottom < documentRect.bottom + rect.height * Math.round(settings.limit*.2) && !settings.dataRichEnd) {
                    settings.loading = true;
                    const loadingPage = settings.previewPagination + 1;
                    this.loadNewData(loadingPage, 'end', settings);
                }

            } else {
                if (e.target.scrollTop <= rect.height * 15  && settings.prevPagination > 0) {
                    settings.loading = true;
                    const loadingPage = (settings.countLastAddedItems < settings.limit &&  settings.countLastAddedItems !== 0) ? settings.prevPagination - 1 : settings.prevPagination;
                    this.loadNewData(loadingPage, 'start', settings);
                }
            }
            settings.lastScrollTop = st <= 0 ? 0 : st;

        }
        this.prevChildTopOffset = e.target.scrollTop;
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        const gameProviderId = this.gameProviderId;

        if (settings.name ===  'debit') {
            this.existingUserService.getUserDebit(this.userId, page, gameProviderId).subscribe(data => {
                this.dataAddingLogic(page, step, data, settings);
            });
        } else if (settings.name ===  'credit') {
            this.existingUserService.getUserCredit(this.userId, page, gameProviderId).subscribe(data => {
                this.dataAddingLogic(page, step, data, settings);
            });
        } else if (settings.name ===  'crypto') {
            this.existingUserService.getUserCrypto(this.userId, page, gameProviderId).subscribe(data => {
                this.dataAddingLogic(page, step, data, settings);
            });
        }
    }

    // ADDING NEW DATA
    dataAddingLogic(page, step, data, settings) {

        console.log(page, step, data, settings);

        // PAGINATION FIXES
        // settings.countTotalItems = data.total;

        if (data.status) {

            // ADD PAGE TO THE END
            if (step === 'end') {

                // UPDATE SETTINGS
                settings.lastAddedItem += data.result.length;
                settings.previewPagination += 1;
                if (+settings.lastAddedItem === +settings.countTotalItems) {
                    settings.dataRichEnd = true;
                }

                // DATA RICH END LOGIC
                if (settings.dataRichEnd) {
                    settings.countLastAddedItems = data.result.length;
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.prevPagination += 1;
                    }

                } else {
                    // REMOVE PAGE FROM BEGINING
                    if (settings.items.length > (settings.limit * 2)) {
                        settings.items.splice(0, data.result.length);
                        settings.prevPagination += 1;
                    }
                }

                // ADD PAGE TO THE END
                for (let i = 0; i < data.result.length; i++) {
                    settings.items.push(data.result[i]);
                };
            }

            // ADD PAGE TO BEGIN
            if ((step === 'start') && settings.prevPagination) {

                let additionalRemovedItems = 0;

                if (settings.countLastAddedItems < settings.limit && settings.countLastAddedItems !== 0) {
                    additionalRemovedItems = settings.countLastAddedItems;
                    settings.prevPagination -= 2;
                    settings.previewPagination -= 2;
                }
                else {
                    settings.prevPagination -= 1;
                    settings.previewPagination -= 1;
                }

                // REMOVE PAGE FROM END
                settings.items.splice(settings.items.length - data.result.length - additionalRemovedItems, data.result.length + additionalRemovedItems);
                settings.lastAddedItem -= data.result.length + additionalRemovedItems;

                // ADD PAGE TO BEGINING
                for (let i = data.result.length-1; i >= 0; i--) {
                    settings.items.unshift(data.result[i]);
                }
                settings.dataRichEnd = false;

            }

            settings.loading = false;
        }
    }

    getPhoneMask(item){
        if(item){
            if(item.country_id){
                return this.phoneMaskService.getPhoneMaskByCountryId(item.country_id);
            }else{
                return this.phoneMaskService.getDefaultPhoneMask();
            }
        } else{
            return this.phoneMaskService.getDefaultPhoneMask();
        }
    }

    getWinningBalance() {
        if (this.userInfo) {
            return this.userInfo.availableWinning;
        }
    }

    pageScroll($event, to) {
        const direction = this.prevPageTopOffset > $event.target.scrollTop ? 'up' : 'down';
        const waitingTime = direction === 'up' ? 500 : 0;
        const pageHasScroll = $event.target.scrollHeight;

        const toOffset = 10;
        this.maxPageScrollPosition = to.offsetTop - toOffset;

        setTimeout(() => {
            if (($event.target.scrollTop > this.maxPageScrollPosition) && pageHasScroll) {

                $event.target.style.overflowY = 'hidden';
                $event.target.scrollTop = this.maxPageScrollPosition;
            }
        }, waitingTime);

        this.prevPageTopOffset = $event.target.scrollTop;
    }

    getBackLink() {
        if (this.singleGame === 'true') {
            return `/home/sell/existing-user`;
        }
        return `/home/sell/existing-user/existing-user-games/${this.userId}`;
    }

    getGameProviderName() {
        return this.gameProviderService.getGameProviderName();
    }

    isTemplate2() {
        return this.templateService.isTemplate2();
    }

    getAmount(action, type) {
        const amount = action.amount;
        const paymentMethod = action.payment_method;

        if (type === this.AMOUNT_TYPE.free && paymentMethod === this.PAYMENT_METHOD_TYPE.free) {
            return amount;
        }

        if (type === this.AMOUNT_TYPE.amount && paymentMethod !== this.PAYMENT_METHOD_TYPE.free) {
            return amount;
        }

        return 0;
    }
}
