import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {REPORTS_CONFIG} from "../../config/api";
import {HttpClient} from "@angular/common/http";
import {LoaderService} from "../loader.service";

@Injectable({
    providedIn: 'root'
})
export class ReportsService {
    private config = REPORTS_CONFIG;

    constructor(private http: HttpClient,
                private loaderService: LoaderService) {

    }

    // getData(singlePage, page, pageCrypto, phone, from, to) {
    //     return this.http.post<any>(this.config.data, {singlePage, page, pageCrypto, phone, from, to});
    // }

    getData(singlePage, page, pageCrypto, phone, from, to) {
        return this.http.post<any>(this.config.data, {singlePage, page, pageCrypto, phone, from, to});
    }

    getInInfo(phone, from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.dataIn, {phone, from, to});
    }

    getOutInfo(phone, from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.dataOut, {phone, from, to});
    }

    getDataPopup(singlePage, page, phone, from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.dataPopup, {singlePage, page, phone, from, to});
    }

    getInInfoPopup(phone, from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.dataInPopup, {phone, from, to});
    }

    getOutInfoPopup(phone, from, to) {
        this.loaderService.show();
        return this.http.post<any>(this.config.dataOutPopup, {phone, from, to});
    }

    getTransactions(id) {
        return this.http.post<any>(this.config.transactions, {id:id});
    }

    reverseTransaction(id) {
        return this.http.post<any>(this.config.reverse, {id});
    }
}
