import {DOMAIN_API} from "./local_settings";
import {DOMAIN_DEMO_API,DOMAIN_DEMO_NAME,DOMAIN_DEMO1_NAME,DOMAIN_INTERIM_API,DOMAIN_INTERIM_NAME} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {Stripe} from "../models/stripe";
import {NewUser} from "../models/new-user";
import {ExistingUser} from "../models/existing-user";
import {Reports} from "../models/reports";
import {AppVersion} from "../models/appVersion";
import {GamesProvider} from "../models/gamesProvider";
import {Collections} from "../models/collections";
import {SellRequestsModel} from "../models/sellRequests.model";
import {FillRequestModel} from "../models/FillRequest.model";

let API = DOMAIN_API + '/pos';
if(window.location.hostname == DOMAIN_DEMO_NAME || window.location.hostname == DOMAIN_DEMO1_NAME){
    API = DOMAIN_DEMO_API + '/pos';
}

if(window.location.hostname == DOMAIN_INTERIM_NAME){
    API = DOMAIN_INTERIM_API + '/pos';
}

const AUTH = API + '/users/session';
const USERS = API + '/users';
const BALANCE = API + '/balance';
const REFILL = BALANCE + '/refill';

export const AUTH_CONFIG: AuthConfig = {
    checkUser: USERS + '/register/check',
    register: USERS + '/register',
    login: AUTH + '/login',
    refresh: AUTH + '/refresh',
    current: AUTH + '/details',
    sendPin: USERS + '/pin/request',
    refreshToken: AUTH + '/refresh',
    checkPhoneAndSendPin: USERS + '/phone/check',
};

export const SELL_CONFIG: SellRequestsModel = {
    getLimit: BALANCE + '/limit',
    requestRefill: BALANCE + '/limit/request',

    // getLimit: API + '/jsons/find/file?name=balance',
    // requestRefill: API + '/jsons/find/file?name=success',
};

export const NEW_USER_CONFIG: NewUser = {
    save: API + '/users/user/createCustomer',
};

export const EXISTING_USER_CONFIG: ExistingUser = {
    getUsers: API + '/users/users',
    userInfo: API + '/users/user/paymentHistory',
    userCredit: API + '/users/user/paymentCredit',
    userDebit: API + '/users/user/paymentDebit',
    userCrypto: API + '/users/user/paymentCrypto',
    sell: API + '/users/user/deposit',
    buy: API + '/users/user/buy',
    freeze: API + '/users/user/freeze',
    delete: API + '/users/user/delete',
    findUserByRiverpayAccountId: API + '/users/user/createCustomerByRiverpayAccountId',
};

export const REPORTS_CONFIG: Reports = {
    data: API + '/reports/reports',
    dataIn: API + '/reports/reports/issued',
    dataOut: API + '/reports/reports/payout',
    dataPopup: API + '/reports/reports/usersInfo',
    dataInPopup: API + '/reports/reports/issuedInfo',
    dataOutPopup: API + '/reports/reports/payoutInfo',
    transactions: API + '/reports/logs',
    reverse: API + '/reports/reverse',

    // data: API + '/jsons/find/file?name=usersInfo',
    // dataIn: API + '/jsons/find/file?name=inInfo',
    // dataOut: API + '/jsons/find/file?name=outInfo',
    // dataPopup: API + '/jsons/find/file?name=usersInfoPopup',
    // dataInPopup: API + '/jsons/find/file?name=inInfoPopup',
    // dataOutPopup: API + '/jsons/find/file?name=outInfoPopup',

};

export const STRIPE_CONFIG: Stripe = {
    getPublishableKey: API+'/app/stripe/publishable-key',
};

export const APP_VERSION_CONFIG: AppVersion = {
    version: API+'/app/version',
};

export const GAMES_PROVIDER_CONFIG: GamesProvider = {
    gamesProviders: API + '/games/vendors'
};

export const COLLECTIONS_CONFIG: Collections = {
    data: API + '/collections/collection',
    details: API + '/collections/collection/details',
    settle: API + '/collections/settle',
    settleVerify: API + '/collections/settle/lists',
    settleConfirm: API + '/collections/settle/create',

    dataSummary: API + '/collections/summary',
    summarySettleVerify: API + '/collections/summary/verify',
    summarySettleConfirm: API + '/collections/summary/create',


    // data: API + '/jsons/find/file?name=collections',
    // details: API + '/jsons/find/file?name=paymentsWeeklyReport',
    // //
    // settle: API + '/jsons/find/file?name=pin',
    // settleVerify: API + '/jsons/find/file?name=collectionsSettleConfirm',
    // settleConfirm: API + '/jsons/find/file?name=success',
    // dataSummary: API + '/jsons/find/file?name=collectionsSummary',
};

export const FILL_REQUEST_CONFIG: FillRequestModel = {
    request: REFILL,
    pin: REFILL + '/pin',
    confirm: REFILL + '/confirm',

    // request: API + '/jsons/find/file?name=success',
    // pin: API + '/jsons/find/file?name=success',
    // confirm: API + '/jsons/find/file?name=success',
}
