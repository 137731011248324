<div class="payments-info-popup is-active" [class.is-active]="showPopup">

    <div class="payments-info-popup__inner">
        <button class="payments-info-popup__close" (click)="onClosePopup()">
            <svg viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                <use xlink:href="../../../../assets/images/sprite.svg#close-sign"></use>
            </svg>
        </button>

        <div *ngIf="popupType === PAYMENTS_INFO_POPUP_TYPE.weekly" class="payments-info-popup__title">{{selectedMonMonth}}/{{selectedMonDay}} - {{selectedSunMonth}}/{{selectedSunDay}}</div>
        <div *ngIf="popupType === PAYMENTS_INFO_POPUP_TYPE.daily" class="payments-info-popup__title">
            <button class="payments-info-popup__btn" (click)="changeDay()"> < </button>
            {{selectedDayName}} {{selectedMonth}}/{{selectedDay}}
            <button class="payments-info-popup__btn payments-info-popup__btn--next" (click)="changeDay(true)"> > </button>
        </div>


        <div *ngIf="data" class="payments-info-popup__table">
            <table class="details-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>MEMBER</th>
                        <th>USDC</th>
                        <th>CRYPTO</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>CASH</td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.cash?.member"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.cash?.usdc"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.cash?.crypto"></app-financial-info-item>
                        </td>
                    </tr>
                    <tr>
                        <td>CARD</td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.card?.member"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.card?.usdc"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.card?.crypto"></app-financial-info-item>
                        </td>
                    </tr>
                    <tr>
                        <td>FEES</td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.fees?.member"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.fees?.usdc"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payments?.fees?.crypto"></app-financial-info-item>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>TOTAL</td>
                        <td>
                            <app-financial-info-item [value]="getTotalFromObject(data.payments, 'member')"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="getTotalFromObject(data.payments, 'usdc')"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="getTotalFromObject(data.payments, 'crypto')"></app-financial-info-item>
                        </td>
                    </tr>
                </tfoot>
            </table>

            <table class="details-table details-table--accent">
                <thead>
                    <tr>
                        <th>PAYOUTS</th>
                        <th>LOCATION</th>
                        <th>VENDOR</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>CASH</td>
                        <td>
                            <app-financial-info-item [value]="data?.payouts?.cash?.location"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payouts?.cash?.vendor"></app-financial-info-item>
                        </td>
                    </tr>
                    <tr>
                        <td>USDC</td>
                        <td>
                            <app-financial-info-item [value]="data?.payouts?.usdc?.location"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payouts?.usdc?.vendor"></app-financial-info-item>
                        </td>
                    </tr>
                    <tr>
                        <td>CHECK</td>
                        <td>
                            <app-financial-info-item [value]="data?.payouts?.check?.location"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payouts?.check?.vendor"></app-financial-info-item>
                        </td>
                    </tr>
                    <tr>
                        <td>PAYPAL</td>
                        <td>
                            <app-financial-info-item [value]="data?.payouts?.paypal?.location"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="data?.payouts?.paypal?.vendor"></app-financial-info-item>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>TOTAL</td>
                        <td>
                            <app-financial-info-item [value]="getTotalFromObject(data.payouts, 'location')"></app-financial-info-item>
                        </td>
                        <td>
                            <app-financial-info-item [value]="getTotalFromObject(data.payouts, 'vendor')"></app-financial-info-item>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>

        <div class="form-simple__btns">
            <button #snakeBtn
                    (click)="popupType === PAYMENTS_INFO_POPUP_TYPE.weekly ? showDailyDetails() : showWeeklyDetails()"
                    class="button snake-btn is-notouched">
                {{popupType === PAYMENTS_INFO_POPUP_TYPE.weekly ? 'DAILY VIEW' : 'SUMMARY'}}
                <svg><rect></rect></svg>
            </button>
        </div>

    </div>

</div>
