import {Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {routerTransition, slideToLeft} from "../../animations/router.animations";
import {ReportsService} from "../../service/reports.service.ts/reports.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExampleHeader} from "../../shared/datapicker-header/datapicker-header";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {PhoneMaskService} from "../../service/phoneMask.service";
import {ResetPageScrollService} from "../../service/pageScroll/resetPageScroll";
import {ReportTransactions, ReportTransactionsItem} from "../../models/reportTransactions/reportTransactions";
import {environment} from "../../../environments/environment";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {VendorService} from "../../service/game/vendors/vendor.service";
import {Router} from "@angular/router";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {DATA_TYPES} from "../../data_types";
import {CollectionsService} from "../../service/collections/collections.service";
import {PIN_POPUP_TYPE} from "../../shared/pin-popup/pin-popup-config";
import {PAYMENTS_INFO_POPUP_TYPE} from "../../shared/payments-info-popup/payments-info-popup-config";
import {DatesService} from "../../service/dates.service";

@Component({
    selector: 'app-collections-summary',
    templateUrl: './collections-summary.component.html',
    styleUrls: ['./collections-summary.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class CollectionsSummaryComponent implements OnInit {
    dateForm: FormGroup;
    exampleHeader = ExampleHeader;

    showDatePicker = false;
    weeks: any;
    allTime = {
        in:0,
        out:0,
        fees:0,
        net:0,
        cash_hand:0,
        remote_hand:0,
        location:0,
        vendor:0,
        settlement:0,
    };


    showPaymentsPopup = false;
    paymentsPopupType: number;
    paymentsPopupDate: any;
    paymentsPopupData;

    showSettlePopup = false;
    settlePopupType: number;
    settlePopupData;

    selectedMonday;
    selectedSunday;
    selectedMonMonth;
    selectedSunMonth;
    selectedMonYear;
    selectedSunYear;

    settled: boolean;

    popupData;

    DATA_TYPES = DATA_TYPES;
    PIN_POPUP_TYPE = PIN_POPUP_TYPE;
    PAYMENTS_INFO_POPUP_TYPE = PAYMENTS_INFO_POPUP_TYPE;

    net = {};
    netTotal: number;
    location = {};

    settleTitle = {
        location: 'OWED TO LOCATION',
        vendor: 'OWED TO VENDOR'
    };

    @ViewChildren('weeksInputs') weeksInputs: QueryList<ElementRef>;

    constructor(private reportsService: ReportsService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService,
                private phoneMaskService: PhoneMaskService,
                private auth:AuthenticationService,
                private resetPageScrollService: ResetPageScrollService,
                private router: Router,
                private formBuilder: FormBuilder,
                private blurMenuService: BlurMenuService,
                private collectionsService: CollectionsService,
                private paginationScrollService: PaginationScrollService,
                private datesService: DatesService,
                private vendorService: VendorService,
                ) {
        const today = new Date();
        const prevTwoMonths = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 60);

        this.dateForm = this.formBuilder.group({
            from: [prevTwoMonths],
            to: [today],
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.getData();
        });
    }

    getData() {
        const from = this.getFilterFrom();
        const to = this.getFilterTo();

        this.collectionsService.getSummaryData(from, to).subscribe(data => {
            this.checkData(data, this.setData.bind(this));
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    getFilterFrom(){
        return (<HTMLInputElement>document.querySelector('[formcontrolname=from]')).value;
    }

    getFilterTo(){
        return (<HTMLInputElement>document.querySelector('[formcontrolname=to]')).value;
    }

    setData(data) {
        this.weeks = data.result;
        this.showDatePicker = false;
        setTimeout(() => {
            this.setCheckBoxesLogic();
        });


        try {
            this.allTime.in = data.in;
            this.allTime.out = data.out;
            this.allTime.fees = data.fees;
            this.allTime.cash_hand = data.cash_hand;
            this.allTime.remote_hand = data.remote_hand;
            this.allTime.net = data.net;
            this.allTime.location = data.location;
            this.allTime.vendor = data.vendor;
            this.allTime.settlement = data.settlement
        }catch (e) {

        }

    }

    setCheckBoxesLogic() {
        const notLastWeekLabels = document.querySelectorAll('.summary-table tbody tr:not(.js-last-week) .summary-checkbox:not(.is-disabled)');
        const notLastWeekCheckboxes = document.querySelectorAll('.summary-table tbody tr:not(.js-last-week) .summary-checkbox:not(.is-disabled) input');
        const lastWeekCheckbox = document.querySelector('.summary-table .js-last-week .summary-checkbox input');

        notLastWeekLabels.forEach(el => {
            (el as HTMLElement).classList.add('is-selectable');
        });
        lastWeekCheckbox.addEventListener('change',(e) => {
            const checked = (e.target as HTMLInputElement).checked;
            notLastWeekCheckboxes.forEach(el => {
                (el as HTMLInputElement).checked = checked;
            });
        });
    }

    // isNetTotalNegative() {
    //     return this.netTotal < 0;
    // }

    checkData(data, successHandler) {
        try {
            if (data.status) {
                successHandler(data);
            } else if (data.status === false) {
                //this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmit() {
        this.collectionsService.onSettle().subscribe(data => {
            this.checkData(data, this.onShopPinPopup.bind(this));
        }, () => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onShopPinPopup(data) {
        this.onShowSettlePopup(this.PIN_POPUP_TYPE.pin, data)
    }

    onShowSettlePopup(type, data) {
        this.showSettlePopup = true;
        this.settlePopupType = type;
        this.settlePopupData = data;
        this.blurMenuService.blur();
    }

    onSettlePopupClose(info) {
        if (info && info.status) {
            switch (info.type) {
                case this.PIN_POPUP_TYPE.pin: {
                    this.confirmPin(info.pin);
                    break;
                }
                case this.PIN_POPUP_TYPE.confirm: {
                    this.confirmSettle();
                    this.resetSettlePopup();
                    break;
                }
            }
        } else {
            this.resetSettlePopup();
        }
    }

    resetSettlePopup() {
        this.showSettlePopup = false;
        this.settlePopupType = null;
        this.settlePopupData = {};
        this.blurMenuService.focus();
    }

    confirmPin(pin) {
        const weeks = this.collectCheckedInputs();

        if (weeks.length) {
            this.collectionsService.onSummarySettleVerify(weeks, pin).subscribe(data => {
                this.checkData(data, this.showSettleConfirmPopup.bind(this));
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    confirmSettle() {
        const weeks = this.collectCheckedInputs();

        if (weeks.length) {
            this.collectionsService.onSummarySettleConfirm(weeks).subscribe(data => {
                this.checkData(data, this.showSuccessSettle.bind(this));
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showSettleConfirmPopup(data) {
        this.resetSettlePopup();
        this.onShowSettlePopup(this.PIN_POPUP_TYPE.confirm, data);
    }

    showSuccessSettle(data) {
        this.popupComponent.showPopupSuccess({text: data.message});
        this.getData();
    }

    isSettleDisabled(date, net?) {
        const dateResult = new Date() < new Date(date);

        if (!Number.isNaN(net) && !dateResult) {
            return net <= 0;
        }
        return dateResult;
    }

    isAllSettled() {
        let result = true;

        if (this.weeks) {
            this.weeks.forEach(week => {
                if (!week.settled) {
                    result = false;
                }
            });
        }

        return result;
    }

    isSettleSubmitDisabled() {
        const lastWeekInput = (document.querySelector('.js-last-week input') as HTMLInputElement);
        if (this.allTime && lastWeekInput) {
            const settlement = !!this.setAllTimeSettlement(this.allTime.net, this.allTime.settlement);
            const isLastWeekChecked = lastWeekInput.checked;
            return !settlement || !isLastWeekChecked;
        }
        return true;

        // const weeksArray = this.collectCheckedInputs();
        //
        // if (!weeksArray.length) {
        //     return true;
        // }
        //
        // let result = true;
        // if (this.weeks) {
        //     this.weeks.forEach(week => {
        //         if (!this.isSettleDisabled(week.date)) {
        //             result = false;
        //         }
        //     });
        // }
        //
        // return result;
    }

    collectCheckedInputs() {
        const weeksArray = [];

        if (this.weeksInputs) {
            this.weeksInputs.forEach(input => {
                if ((<HTMLInputElement>input.nativeElement).checked) {
                    const weekId = input.nativeElement.getAttribute('data-week-id');
                    weeksArray.push(weekId);
                }
            });
        }

        return weeksArray;
    }

    onDateSelectionBtnClick() {
        this.showDatePicker = !this.showDatePicker;
    }

    hideDateSelection($event) {
        if (!document.querySelector('.js-date-selection').contains($event.target)) {
            this.showDatePicker = false;
        }
    }

    checkNetIsPositive(netValue, cellValue?): number {
        if (netValue > 0) {
            if (cellValue) {
                return cellValue;
            }
            return netValue;
        } else {
            return 0;
        }
    }

    setAllTimeSettlement(netValue, settlementValue) {
        let result = this.checkNetIsPositive(netValue, settlementValue);
        return result > 0 ? result : 0;
    }
}
