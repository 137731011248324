import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";
import {SignUpComponent} from "./signup/signup.component";

import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {SellComponent} from "./sell/sell.component";
import {ReportsComponent} from "./reports/reports.component";
import {NewUserComponent} from "./sell/new-user/new-user.component";
import {ExistingUserComponent} from "./sell/existing-user/existing-user.component";
import {ExistingUserInfoComponent} from "./sell/existing-user-info/existing-user-info.component";
import {GamesProviderComponent} from "./games-provider/games-provider.component";
import {CollectionsComponent} from "./collections/collections.component";
import {CollectionsSummaryComponent} from "./collections-summary/collections-summary.component";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {FillRequestFormComponent} from "./fill-request-form/fill-request-form.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: LoginComponent},
            {path: 'login', component: LoginComponent},
            {path: 'signup', component: SignUpComponent},
            // {path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
            {path: 'home', component: HomeComponent, children: [
                    {path: 'sell', component: SellComponent, children: [

                        ]},
                    {path: 'reports', component: ReportsComponent},
                    {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
                    {path: 'fill-request-form', component: FillRequestFormComponent},
                    {path: 'collections', component: CollectionsComponent},
                    {path: 'collections/summary', component: CollectionsSummaryComponent},
                    {path: 'sell/new-user/:gameId', component: NewUserComponent},
                    {path: 'sell/games-providers/new-user', component: GamesProviderComponent},
                    {path: 'sell/existing-user', component: ExistingUserComponent},
                    {path: 'sell/existing-user/existing-user-info/:id', component: ExistingUserInfoComponent},
                    {path: 'sell/existing-user/existing-user-games/:id', component: GamesProviderComponent},
                ]},
            {path: '**', redirectTo: 'login', pathMatch: 'full'}
        ]
    }

];



@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
