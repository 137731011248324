import { REPORTS_CONFIG } from "../../config/api";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../loader.service";
var ReportsService = /** @class */ (function () {
    function ReportsService(http, loaderService) {
        this.http = http;
        this.loaderService = loaderService;
        this.config = REPORTS_CONFIG;
    }
    // getData(singlePage, page, pageCrypto, phone, from, to) {
    //     return this.http.post<any>(this.config.data, {singlePage, page, pageCrypto, phone, from, to});
    // }
    ReportsService.prototype.getData = function (singlePage, page, pageCrypto, phone, from, to) {
        return this.http.post(this.config.data, { singlePage: singlePage, page: page, pageCrypto: pageCrypto, phone: phone, from: from, to: to });
    };
    ReportsService.prototype.getInInfo = function (phone, from, to) {
        this.loaderService.show();
        return this.http.post(this.config.dataIn, { phone: phone, from: from, to: to });
    };
    ReportsService.prototype.getOutInfo = function (phone, from, to) {
        this.loaderService.show();
        return this.http.post(this.config.dataOut, { phone: phone, from: from, to: to });
    };
    ReportsService.prototype.getDataPopup = function (singlePage, page, phone, from, to) {
        this.loaderService.show();
        return this.http.post(this.config.dataPopup, { singlePage: singlePage, page: page, phone: phone, from: from, to: to });
    };
    ReportsService.prototype.getInInfoPopup = function (phone, from, to) {
        this.loaderService.show();
        return this.http.post(this.config.dataInPopup, { phone: phone, from: from, to: to });
    };
    ReportsService.prototype.getOutInfoPopup = function (phone, from, to) {
        this.loaderService.show();
        return this.http.post(this.config.dataOutPopup, { phone: phone, from: from, to: to });
    };
    ReportsService.prototype.getTransactions = function (id) {
        return this.http.post(this.config.transactions, { id: id });
    };
    ReportsService.prototype.reverseTransaction = function (id) {
        return this.http.post(this.config.reverse, { id: id });
    };
    ReportsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReportsService_Factory() { return new ReportsService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoaderService)); }, token: ReportsService, providedIn: "root" });
    return ReportsService;
}());
export { ReportsService };
