import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {AppVersionrService} from "../../service/app-version/appVersionr.service";
import {BlurMenuService} from "../../service/blurMenu.service";
import {TemplateService} from "../../service/template.service";
import {RouterPaths} from "../../config/router-paths.model";
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    menu = false;
    menuBlurred = false;

    isRiverPay = this.partnerService.isRiverPay();

    ROUTER_PATHS = RouterPaths;

    constructor(
        private auth:AuthenticationService,
        private appVersionrService: AppVersionrService,
        private blurMenuService: BlurMenuService,
        private router: Router,
        private templateService: TemplateService,
        private partnerService: PartnerService,
    ) {
        this.blurMenuService.menuBlurred.subscribe(value => this.blurMenuChanged(value));

    }


    ngOnInit() {

    }

    logout(){
        this.auth.logout();

        this.router.navigate(['/']);
    }

    showMenu() {
        this.menu = !this.menu;
    }

    upgradeApp(){
        this.appVersionrService.upgradeApp();
    }

    blurMenuChanged(value) {
        this.menuBlurred = value;
    }

    isTemplate2() {
      return this.templateService.isTemplate2();
    }

    getSellLink() {
      return this.isTemplate2() ? this.ROUTER_PATHS.sell.existingUser.list : this.ROUTER_PATHS.sell.main;
    }

    goToCollections() {
        this.router.navigate([this.ROUTER_PATHS.collections.main]);
        this.showMenu();
    }

    goToFillRequestForm() {
        this.router.navigate([this.ROUTER_PATHS.fillRequestForm.main]);
        this.showMenu();
    }

    goToCollectionsSummary() {
        this.router.navigate([this.ROUTER_PATHS.collections.summary]);
        this.showMenu();
    }

    goToTermsAndConditions() {
        this.router.navigate([this.ROUTER_PATHS.termsAndConditions.main]);
        this.showMenu();
    }
}
