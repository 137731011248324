/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./conclusion.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./conclusion.component";
var styles_ConclusionComponent = [i0.styles];
var RenderType_ConclusionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConclusionComponent, data: {} });
export { RenderType_ConclusionComponent as RenderType_ConclusionComponent };
function View_ConclusionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "conclusion__row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "conclusion__side"]], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "conclusion__side"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), i1.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.amount)); _ck(_v, 3, 0, currVal_1); }); }
function View_ConclusionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h2", [["class", "conclusion__result"]], [[2, "is-negative", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isNegativeNumber(_co.data.result); _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.getAbs(_co.data.result))); _ck(_v, 1, 0, currVal_1); }); }
function View_ConclusionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "conclusion__result"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data.result; _ck(_v, 1, 0, currVal_0); }); }
export function View_ConclusionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.CurrencyPipe, [i1.LOCALE_ID]), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "conclusion"]], [[2, "conclusion--sm", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConclusionComponent_1)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "h2", [["class", "conclusion__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConclusionComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ConclusionComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.list; _ck(_v, 3, 0, currVal_1); var currVal_3 = !_co.isString(_co.data.result); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.isString(_co.data.result); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type && (_co.type === "sm")); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.data.title; _ck(_v, 5, 0, currVal_2); }); }
export function View_ConclusionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-conclusion", [], null, null, null, View_ConclusionComponent_0, RenderType_ConclusionComponent)), i1.ɵdid(1, 114688, null, 0, i3.ConclusionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConclusionComponentNgFactory = i1.ɵccf("app-conclusion", i3.ConclusionComponent, View_ConclusionComponent_Host_0, { data: "data", type: "type" }, {}, []);
export { ConclusionComponentNgFactory as ConclusionComponentNgFactory };
