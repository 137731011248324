<div class="page page--scrolled" [class.is-ios]="isIos()"
                                 [class.is-blurred]="transactionsResultVisibility || showPopupInfo">
    <div class="page__inner">
        <div class="page__form">
            <form [formGroup]="reportsForm" (ngSubmit)="onSubmit()" class="form-simple">
                <div class="form-simple__item">
                    <input [mask]=phoneMask
                           formControlName="phone"
                           placeholder="Phone Number"
                           [type]="'text'"
                           class="form-simple__input"
                           autocomplete="off">
                </div>

                <div class="form-simple__row">
                    <div class="form-simple__item">
                        <mat-form-field appearance="outline">
                            <input formControlName="from" readonly placeholder="From" matInput [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                                <svg matDatepickerToggleIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                                    <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                                    <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                                    <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                                    <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                                    <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                                    <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
                        </mat-form-field>
                    </div>
                    <div class="form-simple__item">
                        <mat-form-field appearance="outline">
                            <input formControlName="to" readonly placeholder="To" matInput [matDatepicker]="pickerTo">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo">
                                <svg matDatepickerToggleIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.5338 2.43848H7.46387V3.84468H16.5338V2.43848Z" fill="white"/>
                                    <path d="M6.05727 3.14148H4.66699V3.68846H6.05727V3.14148Z" fill="white"/>
                                    <path d="M19.3307 3.14148H17.9404V3.68846H19.3307V3.14148Z" fill="white"/>
                                    <path d="M20.7372 2.43848V5.09479H16.5345V3.14155H7.46456V5.09479H3.26189V2.43848H0V6.79766H23.9991V2.43848H20.7372Z" fill="white"/>
                                    <path d="M0 8.20386V24.0001H23.9991V8.20386H0ZM5.24512 21.3752H2.92163V19.969H5.24512V21.3752ZM5.24512 18.3285H2.92163V16.9223H5.24512V18.3285ZM5.24512 15.2817H2.92163V13.8755H5.24512V15.2817ZM9.20306 21.3752H6.88003V19.969H9.20306V21.3752ZM9.20306 18.3285H6.88003V16.9223H9.20306V18.3285ZM9.20306 15.2817H6.88003V13.8755H9.20306V15.2817ZM13.161 21.3752H10.838V19.969H13.161V21.3752ZM13.161 18.3285H10.838V16.9223H13.161V18.3285ZM13.161 15.2817H10.838V13.8755H13.161V15.2817ZM13.161 12.235H10.838V10.8288H13.161V12.235ZM17.119 21.3752H14.796V19.969H17.119V21.3752ZM17.119 18.3285H14.796V16.9223H17.119V18.3285ZM17.119 15.2817H14.796V13.8755H17.119V15.2817ZM17.119 12.235H14.796V10.8288H17.119V12.235ZM21.0774 18.3285H18.7539V16.9223H21.0774V18.3285ZM21.0774 15.2817H18.7539V13.8755H21.0774V15.2817ZM21.0774 12.235H18.7539V10.8288H21.0774V12.235Z" fill="white"/>
                                    <path d="M6.05727 0H4.66699V3.68845H6.05727V0Z" fill="white"/>
                                    <path d="M19.3307 0H17.9404V3.68845H19.3307V0Z" fill="white"/>
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #pickerTo [calendarHeaderComponent]="exampleHeader"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-simple__info">
                    All dates and times are expressed in UTC.
                </div>

                <div class="form-simple__btns">
                    <button #filterBtn class="button snake-btn is-notouched">
                        FILTER
                        <svg><rect></rect></svg>
                    </button>
                </div>

<!--                <div class="form-simple__btns summary__col" style="width: 100%;">-->
<!--                    Your eBox Earnings-->
<!--                </div>-->
            </form>
        </div>

        <div class="page__tabs">
            <div class="tabs">
                <button #tabsEl *ngFor="let tab of tabs; let isFirst = first"
                        (click)="onTabChange($event, tab.id)"
                        [class.is-active]="isFirst"
                        class="tabs__btn button snake-btn is-notouched">
                    {{tab.title}}
                    <svg><rect></rect></svg>
                </button>
            </div>
        </div>

        <div class="page__body">
            <div class="page__head">
                <app-summary [items]="headerData"></app-summary>

                <div *ngIf="this.activeTabId != this.tabs[0].id && !isRiverPay" class="more-info">
                    <button class="more-info"
                            (click)="onShowPopupInfo()">i</button>
                </div>
            </div>

<!--            <div class="users-list js-info-body" *ngIf="showUsersList">-->
<!--                <div class="users-list__inner" (scroll)="scroll($event, usersData)">-->
<!--                    &lt;!&ndash;                <div class="dropdown__body">&ndash;&gt;-->
<!--                    <table class="info info&#45;&#45;accent">-->
<!--                        <thead class="info__head">-->
<!--                        <tr>-->
<!--&lt;!&ndash;                            <th *ngIf="isShowReportsLogs()"></th>&ndash;&gt;-->
<!--                            <th class="td-phone">USER</th>-->
<!--&lt;!&ndash;                            <th>FREE</th>&ndash;&gt;-->
<!--                            <th>ISSUED</th>-->
<!--                            <th>WINS</th>-->
<!--&lt;!&ndash;                            <th>REDEE</th>&ndash;&gt;-->
<!--                        </tr>-->
<!--                        </thead>-->

<!--                        <tbody class="info__body">-->
<!--                        <tr class="info__row js-info-row" *ngFor="let user of usersData.items">-->
<!--&lt;!&ndash;                            <td *ngIf="isShowReportsLogs()">&ndash;&gt;-->
<!--&lt;!&ndash;                                <button (click)="getTransactions(user.id)" class="info__btn">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <svg class="info__btn-icon" width="60" height="60">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <use xlink:href="../../../../assets/images/sprite.svg#list"></use>&ndash;&gt;-->
<!--&lt;!&ndash;                                    </svg>&ndash;&gt;-->
<!--&lt;!&ndash;                                </button>&ndash;&gt;-->
<!--&lt;!&ndash;                            </td>&ndash;&gt;-->
<!--                            <td class="td-phone">-->
<!--                                <button class="info__title"-->
<!--                                        (click)="openUserInfo(user.id)">-->
<!--                                    {{user.phone | mask: getPhoneMask(user)}}-->
<!--                                </button>-->
<!--                                <div class="info__subtitle">{{user.pin}}</div>-->
<!--                            </td>-->
<!--&lt;!&ndash;                            <td>{{user.free | currency}}</td>&ndash;&gt;-->
<!--                            <td>{{getIssued(user) | currency}}</td>-->
<!--                            <td>{{getWins(user) | currency}}</td>-->
<!--&lt;!&ndash;                            <td>{{user.payout | currency}}</td>&ndash;&gt;-->
<!--                        </tr>-->
<!--                        <tbody>-->
<!--                    </table>-->
<!--                </div>-->
<!--            </div>-->

            <div class="dropdown js-dropdown js-info-body is-hidden">
                <div class="dropdown__head" (click)="toggleDropdown($event)">
                    <div *ngIf="!isRiverPay" class="dropdown__title">
                        MEMBERSHIP DUES
                    </div>
                    <div *ngIf="isRiverPay" class="dropdown__title">
                        LAYAWAY PURCHASES
                    </div>
                    <!--                    <div class="dropdown__total">-->
                    <!--                        {{getRedemtions(userInfo) | currency}}-->
                    <!--                    </div>-->
                    <svg class="dropdown__arrow" width="21" height="12">
                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                    </svg>
                </div>
                <div class="dropdown__body" (scroll)="scroll($event, usersData)">
                    <!--                <div class="dropdown__body">-->
                    <table class="info">
                        <thead class="info__head">
                        <tr>
                            <th *ngIf="isShowReportsLogs()"></th>
                            <th class="td-phone">USER</th>
                            <th>FREE</th>
                            <th>ISSUED</th>
                            <th>REDEE</th>
                        </tr>
                        </thead>

                        <tbody class="info__body">
                        <tr class="info__row js-info-row" *ngFor="let user of usersData.items">
                            <td *ngIf="isShowReportsLogs()">
                                <button (click)="getTransactions(user.id)" class="info__btn">
                                    <svg class="info__btn-icon" width="60" height="60">
                                        <use xlink:href="../../../../assets/images/sprite.svg#list"></use>
                                    </svg>
                                </button>
                            </td>
                            <td class="td-phone">
                                <button class="info__title"
                                        (click)="openUserInfo(user.id)">
                                    {{user.phone | mask: getPhoneMask(user)}}
                                </button>
                                <div class="info__subtitle">{{user.pin}}</div>
                            </td>
                            <td>{{user.free | currency}}</td>
                            <td>{{getIssued(user) | currency}}</td>
                            <td>{{user.payout | currency}}</td>
                        </tr>
                        <tbody>
                    </table>
                </div>
            </div>

            <div class="dropdown js-dropdown js-crypto-parent is-hidden" [hidden]="isRiverPay">
                <div class="dropdown__head" (click)="toggleDropdown($event)">
                    <div class="dropdown__title">
                        Sold Crypto
                    </div>
                    <!--                    <div class="dropdown__total">-->
                    <!--                        {{getCryptoTotal(userInfo) | currency}}-->
                    <!--                    </div>-->
                    <svg class="dropdown__arrow" width="21" height="12">
                        <use xlink:href="../../../../assets/images/sprite.svg#arrow-bottom"></use>
                    </svg>
                </div>
                <div class="dropdown__body" (scroll)="scroll($event, cryptoData)">
                    <!--                <div class="dropdown__body">-->
                    <table class="transactions">
                        <tr class="transactions__head">
                            <th class="transactions__phone">Phone</th>
                            <th class="transactions__sold">Sold</th>
                        </tr>
                        <tr *ngFor="let action of cryptoData.items" class="transactions__item js-crypto-item">
                            <td (click)="openUserInfo(action.id)" class="transactions__phone">{{action.phone | mask: getPhoneMask(action)}}</td>
                            <td class="transactions__sold">{{action.amount | currency}}</td>
                        </tr>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>


<app-transactions-popup *ngIf="transactionsResultVisibility"
                        [transactionsResult]="transactionsResult.transformItems"
                        (close)="hideTransactionsResult()"
                        (reverseAction)="reverseHandler()"
></app-transactions-popup>

<app-more-info [showPopup]="showPopupInfo"
               [items]="popupData"
               *ngIf="!isRiverPay"
                (close)="onHidePopupInfo()"></app-more-info>
